import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let _root = this;

export default class Parallax {
  constructor() {
    _root = this;
    this.refresh();
  }

  refresh() {
    this.setSize();
  }

  setSize() {
    if (window.innerWidth >= 992) {
      this.startMoveElements();
      this.startMoveBgParallax();
      this.enterElements();
    }
  }
  startMoveElements() {
    let element = document.querySelectorAll(".parallax-item");
    if ("undefined" != typeof element && null != element) {
      const tl = gsap.timeline({
        scrollTrigger: {
          scrub: true,
        },
      });
      gsap.utils.toArray(".parallax-item").forEach((layer) => {
        const depth = layer.dataset.depth;
        // const up = layer.dataset.way;
        // const movement = -(layer.offsetHeight * depth);
        // console.log(layer.offsetHeight);
        const movement = -(200 * depth);

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: layer.parentElement,
            scrub: true,
          },
        });

        tl.to(
          layer,
          {
            y: movement,
            ease: "none",
          },
          0
        );

        if (layer.dataset.rotate) {
          tl.to(
            layer.querySelector("path"),
            {
              rotation: layer.dataset.rotate,
              scale: 0.8,
              transformOrigin: "center center",
              ease: "none",
            },
            0
          );
        }
      });
    }
  }

  // CONTENITORE ESTERNO .parallax-img__container
  // ELEMENTO INTERNO     .parallax-img__img
  // data-depth 1.4 PRIMO ELEMENTO
  // data-depth 1.2 SECONDO ELEMENTO

  startMoveBgParallax() {
    let element = document.querySelectorAll(".parallax-img__container");
    if ("undefined" != typeof element && null != element) {
      gsap.utils
        .toArray(".parallax-img__container .parallax-img__img")
        .forEach((section) => {
          const heightDiff =
            section.offsetHeight - section.parentElement.offsetHeight;
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: section.parentElement,
              scrub: true,
            },
          });
          let direction = -heightDiff;
          if (section.dataset.inverted) {
            direction = -(heightDiff / 2);
          }
          tl.to(
            section,
            {
              y: direction,
              ease: "none",
            },
            0
          );
        });
    }
  }

  enterElements() {
    let container = document.querySelectorAll(".gsap-fadein-wrapper");
    if ("undefined" != typeof container && null != container) {
      container.forEach(function (el) {
        gsap.fromTo(
          el.querySelectorAll(".gsap-fadein"),
          // from
          {
            y: -50,
            autoAlpha: 0,
          },
          // to
          {
            y: 0,
            stagger: 0.3,
            autoAlpha: 1,
            scrollTrigger: {
              trigger: el,
              start: "-300px center",
              end: "80% center",
              // markers: true,
            },
          }
        );
      });

      // let cards = gsap.utils.toArray(".gsap-fadein");
      // gsap.set(cards, { autoAlpha: 0, y: -50 });
      // // let container = gsap.utils.toArray(".gsap-fadein-wrapper");
      // cards.forEach((card, i) => {
      //   const anim = gsap.to(card, {
      //     duration: 0.8 * (i + 1),
      //     autoAlpha: 1,
      //     y: 0,
      //     paused: true,
      //     ease: "sine.inOut",
      //   });
      //   ScrollTrigger.create({
      //     trigger: card,
      //     start: "20% center",
      //     end: "80% center",
      //     // once: true,
      //     toggleActions: "play none none reverse",
      //     onEnter: (self) => {
      //       // console.log(`${container.innerHeight}`);
      //       self.progress === 1 ? anim.progress(1) : anim.play();
      //       // gsap.to(card, {
      //       //   duration: 0.8 * (i + 1),
      //       //   autoAlpha: 1,
      //       //   y: 0,
      //       //   paused: true,
      //       //   ease: "sine.inOut",
      //       // });
      //       // gsap.set(self, { autoAlpha: 1, y: 0 });
      //     },
      //     // onEnterBack: (self) => {
      //     //   // console.log(self.direction);
      //     //   // self.progress === 1 ? anim.progress(1) : anim.play();
      //     //   gsap.to(card, {

      //     //     duration: 0.8 * (i + 1),
      //     //     autoAlpha: 0,
      //     //     y: -50,
      //     //     paused: true,
      //     //     ease: "sine.inOut",
      //     //   });
      //     // },
      //     // onLeave: (self) => {
      //     //   // self.progress === 1 ? anim.progress(0) : anim.play();
      //     //   gsap.set(cards, { autoAlpha: 0, y: -50 });
      //     // },
      //   });
      // });
    }
  }
  enterElement() {
    let element = document.getElementsByClassName("gsap-fadein");
    if ("undefined" != typeof element && null != element) {
      gsap.fromTo(
        element,
        // from
        {
          y: -50,
          autoAlpha: 0,
        },
        // to
        {
          y: 0,
          stagger: 0.3,
          autoAlpha: 1,
          scrollTrigger: {
            trigger: element,
            start: "bottom center",
            end: "80% center",
          },
        }
      );
    }
  }
}

// const boxes = gsap.utils.toArray(".box");

// // Set things up
// gsap.set(boxes, { autoAlpha: 0, y: 50 });
// gsap.set(els, { autoAlpha: 0, y: 50 });

// boxes.forEach((box, i) => {
//   // Set up your animation
//   const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });

//   // Use callbacks to control the state of the animation
//   ScrollTrigger.create({
//     trigger: box,
//     end: "bottom bottom",
//     once: true,
//     onEnter: (self) => {
//       // If it's scrolled past, set the state
//       // If it's scrolled to, play it
//       self.progress === 1 ? anim.progress(1) : anim.play();
//     },
//   });
// });
